const initialState = {
  menu: [],
};

const mainMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MAIN_MENU":
      return { ...state, menu: action.payload };
    case "SET_IP_ADDRESS":
      return { ...state, setIpAddress: action.payload };
    case "SET_COUNTRY_STATE_CITY":
      return {
        ...state,
        countryStateCityData: action.payload,
      };
    default:
      return state;
  }
};

export default mainMenuReducer;

import React, { Suspense, lazy, useEffect } from "react";
import './App.css';
import { BrowserRouter, Route, Routes, useNavigate, Outlet } from 'react-router-dom';
import Loader from "./app-dt-sell/components/loader";
import { Provider } from 'react-redux';
import store from './app-dt-sell/store';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { COLOR } from "./app-dt-sell/common/constant";

// Lazy load components
const Login = lazy(() => import("./app-dt-sell/auth/login"));
const Dashboard = lazy(() => import("./app-dt-sell/dashboard"));
const Company = lazy(() => import("./app-dt-sell/company"));
const Branch = lazy(() => import("./app-dt-sell/branch"));
const Party = lazy(() => import("./app-dt-sell/party"));
const Layout = lazy(() => import("./app-dt-sell/auth/layout"));
const NotFound = lazy(() => import("./app-dt-sell/components/NotFound"));

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR.primary
    }
  }
});

function App() {
 
  return (
    <React.Fragment>
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<AuthRoute><Login /></AuthRoute>} />
                {/* Private Routes */}
                <Route element={<PrivateRoute />}>
                  <Route path="/dashboard" element={<Layout><Dashboard /></Layout>} />
                  <Route path="/company" element={<Layout><Company type_id={0} form_name="Company" /></Layout>} />
                  <Route path="/branch" element={<Layout><Branch type_id={0} form_name="Branch" /></Layout>} />
                  <Route path="/party" element={<Layout><Party type_id={0} form_name="Party" /></Layout>} />
                </Route>

                {/* Catch-All Route */}
                <Route path="*" element={<PrivateRoute><NotFound /></PrivateRoute>} />
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </Suspense>
    </React.Fragment>
  );
}

// PrivateRoute component
const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("userAuth");

  useEffect(() => {
    if (!token) {
      navigate("/"); // Redirect to login if no token
    }
  }, [token, navigate]);

  if (!token) {
    return null; // Don't render children if user is not authenticated
  }

  return children ? children : <Outlet />; // Render children or the child route (e.g., dashboard or company)
};

// AuthRoute component to redirect if authenticated
const AuthRoute = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("userAuth");

  useEffect(() => {
    if (token) {
      navigate("/dashboard"); // Redirect to dashboard if authenticated
    } else {
      navigate("/");
    }
  }, [token, navigate]);

  if (token) {
    return null; // Don't render the login or register if user is authenticated
  }

  return children; // Render the child route (e.g., login or register)
};

export default App;
